<template>
  <div>
 
    <v-row class="px-5 py-10">
    
    <v-col cols="12" sm="12" md="12" lg="3" >
      <!-- Ordered By Date -->
      <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
       <template v-slot:activator="{ on, attrs }">
         <v-text-field color="primarytext" persistent-placeholder 
           :value="computedDateFormattedMomentjs" dense clearable outlined label="Ordered Date" readonly v-bind="attrs" v-on="on"
           @click:clear="order_Date = null"></v-text-field>
       </template>
       <v-date-picker v-model="order_Date" @change="menu1 = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="12" md="12" lg="3">
      <v-icon color="secondary"
                    title="Order will be saved as per EST Time zone">mdi
                    mdi-information-slab-circle-outline</v-icon>
                  </v-col>
  </v-row>
  <v-btn outlined  class="ma-1 mx-1" color="primarytext" @click="onClickSubmit()">
                Submit
              </v-btn>
              <v-template v-if="showDate">
                {{localDate}}
                {{order_Date}}
              </v-template>

  </div>
</template>

<script>
import Vue from 'vue';
import excel from 'vue-excel-export';
import moment from "moment";
//import breadcrumbComp from '../common/breadcrumb-comp.vue';
//import EncryptUtility from '../utility/js/encrypt-utility';
//import AddFavourite from "../utility/js/addFav"
//import companyComp from './app-company-comp.vue';
//import Utility from '../../shared/utility';
//import exportLimit from '../common/export-limit.vue';
//import commonApiService from "../common/js/common-api-service";
Vue.use(excel);
export default {
  data: () => ({
  localDate:new Date(),
  order_Date:"",
  menu1:false,
  test:"",
  showDate:false,
  }),
  computed: {
  computedDateFormattedMomentjs() {
      return this.order_Date
        ? moment(this.order_Date).format("MM/DD/YYYY")
        : moment(new Date()).format("MM/DD/YYYY");
        
    },
  },  
  
  async mounted() {
    console.log(this.order_Date)
    
 
  },
  methods: {
    onClickSubmit(){
      this.showDate=true;
      console.log(this.order_Date);
      // To check if the date is in ISO format or not
      if(this.isIsoDate(this.order_Date)||  !this.order_Date){
        this.order_Date=new Date();
      this.order_Date=new Date(Date.UTC(this.order_Date.getUTCFullYear(), this.order_Date.getUTCMonth(),this.order_Date.getUTCDate())).toISOString().substr(0, 10);
      console.log(this.order_Date)}
    },

    isIsoDate(str) {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
        const d = new Date(str); 
        return d instanceof Date && !isNaN(d.getTime()) && d.toISOString()===str; // valid date 
      }
    
  },

};
</script>
